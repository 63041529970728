.Leaderboard-user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid lightgray;
    padding: 0.5em 1em;
    margin: 0.5em 0;
}
.Leaderboard-profile {
    width: 8em;
}
.Leaderboard-user:nth-child(2n+1) {
    background-color: var(--card-background-dark);
}
.Leaderboard-user-stats {
    display: flex;
    flex-direction: column;
}
.Leaderboard-user-score {
    font-size: 1.5em;
}